// Functionality for lazy load of images

class lazyLoad {
    constructor(imagesAttr, options = {}) {
        // Default options 
        this.defaultOptions = {
            rootMargin: "0px",
            root: null,
            threshold: 0,
            classDefault: '',
            classOnLoad: '',
            allowDBCache: false
        }

        // Set init options as default
        this.options = this.defaultOptions;

        // Get user options
        this.userOptions = options;

        // Override options with given
        this.options = Object.assign(this.options, this.userOptions);

        // Find all images
        this.images = document.querySelectorAll(imagesAttr);
        // Check if there are any images
        if (this.images.length > 0) {
            // Check if functionality to cache images using IndexDB has been activated from options
            // NOT SUPPORTED YET


            // Init intersection observer to handle change of src if image in viewport
            if ('IntersectionObserver' in window) {
                // Create options for observer
                const observerOptions = {
                    root: this.options.root,
                    rootMargin: this.options.rootMargin,
                    threshold: this.options.threshold
                }

                const lazyImagesObserver = new IntersectionObserver((entries, observer) => {
                    // Handle all entries (all images in this case)
                    entries.forEach(entry => {
                        // Check if image should be active
                        if (entry.isIntersecting) {
                            // Get image
                            let image = entry.target;
                            // Check if is already loaded or is currently loading
                            if (image.getAttribute('data-lazy-loaded') != "true" || image.getAttribute('data-lazy-loading') != "true") {
                                // If is not loaded or loading, add attr that is loading now
                                image.setAttribute("data-lazy-loading", "true");

                                // Add listener to load
                                image.addEventListener('load', () => {
                                    // On load, add specified class
                                    image.classList.add(this.options.classOnLoad);

                                    // unobserve this image
                                    lazyImagesObserver.unobserve(image);

                                    // Remove attr that is loading
                                    image.removeAttribute("data-lazy-loading");
                                    // Add attr that is already loaded
                                    image.setAttribute("data-lazy-loaded", "true");
                                })

                                // Override src
                                image.src = image.getAttribute("data-src");
                            }

                            // Override src

                        }
                    })
                }, observerOptions)

                // Loop through all images and observe them
                this.images.forEach(lazyImage => {
                    // Add class specified in options to every image
                    lazyImage.classList.add(this.options.classDefault);

                    lazyImagesObserver.observe(lazyImage);
                })
            }
        }
    }
}

// Init lazy load
window.addEventListener('load', () => {
    new lazyLoad("[data-lazy='active']", {
        rootMargin: "200px",
        classDefault: 'lazy-image',
        classOnLoad: 'lazy-image--active',
        allowDBCache: true,
    })
});
