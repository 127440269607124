// Imports:

// Styles:
import 'bootstrap/scss/bootstrap.scss';

// Main.scss should always be last, otherwise your styles can be overwrite by styles from libraries
import '../scss/main.scss';


// Common libraries: 
import $ from 'jquery';
window.jQuery = $;
window.$ = $;


// Popper - neccessary for bootstrap
import Popper from 'popper.js/dist/umd/popper.js';
window.Popper = Popper;

import 'bootstrap';

// Plugins
import './plugins/PluginLoadDependecies';
import './plugins/PluginLazyLoad';
import './plugins/PluginOutline';
import './plugins/PluginDeviceType';
import './plugins/PluginSVGConverter';
import './components/ComponentAOS';



// Components:
// 

// Script to handle menuPages
// Handle show/hide of menu with all pages

// Get menu element
let BwMenuPages = $("[data-item='BW-navigation-menu']");

// Check if menu exists
if (BwMenuPages.length > 0) {
    // Show/hide on button with menu icon
    $("[data-item='BW-navigation-button']").on("click", () => {
        if (BwMenuPages.length > 0) {
            // Check if menu is visible
            if (BwMenuPages.hasClass("visible") !== true) {
                // If true -> animate show
                BwMenuPages.addClass("visible");
                BwMenuPages.animate({ "opacity": "1" }, 300);
            }
            else {
                // If false -> animate hide
                BwMenuPages.animate({ "opacity": "0" }, 300, function () {
                    BwMenuPages.removeClass("visible");
                });
            }
        }
    });

    // Hide on button 'X" click
    $("[data-action='BW-navigation-hide']").on("click", () => {
        if (BwMenuPages.length > 0) {
            // Animate hide
            BwMenuPages.animate({ "opacity": "0" }, 300, function () {
                BwMenuPages.removeClass("visible");
            });
        }
    });
}

// Remove preload class on load
window.addEventListener('load', () => {

    // hide loader
    let loader = $("[data-item='loader']");

    window.setTimeout( ()=> {
        loader.animate({ "opacity": 0 }, 300, () => {
            loader.addClass("hidden");
        });
    }, 250);


    // Get body
    document.getElementsByTagName("body")[0].classList.remove("preload");

    
})