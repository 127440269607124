// Script to load dependencies (js and css) into website for every section 
function loadDependence(type, name, loadedVariable) {
    // Check if elements is already added (if is in loaded*Name* variable)
    let path;

    // Get correct path depends of type from meta tag
    const mode = document.querySelector("[name='bw:dependencies']").getAttribute("content");

    switch(mode) {
        case 'development':
            path = "assets/";
            break;
        case "production":
            path = "/";
            break;
    }

    if(loadedVariable.indexOf(name) === -1) {
        // If not, add element to variable
        loadedVariable.push(name);

        // Find specific file

        let file;
        let tag;
        if(type === "script") {
            file = path + "js/" + name + ".js";
            let script = document.createElement("script");  // create a script DOM node
            script.type="text/javascript";
            script.src = file;  // set its src to the provided URL

            document.head.appendChild(script); 
        }

        if(type === "styles") {
            file = path + "css/" + name + ".css";
            // Create DOM link element
            let style = document.createElement("link");  
            style.rel = "stylesheet";  
            style.media="print"
            style.setAttribute("onload","if(media!='all')media='all'");
            style.href = file;  // set its src to the provided URL

            document.head.appendChild(style); 
        }           
    }
    else {
        // console.log(name + "  loaded");
    }
}


// Helper variables for list of already loaded styles and scripts
var loadedScripts = [""];
var loadedStyles = [""];

// Init loadDependence for every data-style and data-script attr
let dataStyles = document.querySelectorAll("[data-styles]");

// Loop through elements with dataStyles
dataStyles.forEach((e) => {
    // Get names of scripts from data script
    let styles = e.getAttribute("data-styles");
    // Split styles to array
    styles = styles.split(" ");
    
    // Loop through specified dependences, and execute loadDependence function for every
    styles.forEach((e) => {
        loadDependence("styles", e, loadedStyles);
    });
});

let dataScripts = document.querySelectorAll("[data-scripts]");

// Loop through elements with dataStyles
dataScripts.forEach((e) => {
    // Get names of scripts from data script
    let scripts = e.getAttribute("data-scripts");
    // Split styles to array
    scripts = scripts.split(" ");
    
    // Loop through specified dependences, and execute loadDependence function for every
    scripts.forEach((e) => {
        loadDependence("script", e, loadedScripts);
    });
});



