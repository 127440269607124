
class convertSVG {
    constructor(controller) {
        this.controller = controller;
    }

    convertSVG(svg = null) {

        let itemSVG = null;

        /* Convert SVG */
        if (svg) {
            itemSVG = svg;
        } else {
            itemSVG = document.querySelectorAll('[data-item="svg"]');
        }

        itemSVG.forEach(function (el) {
            let img = el

            let imgID = img.getAttribute("id");
            let imgClass = img.getAttribute("class");
            let imgURL = img.getAttribute("src");
            let imgALT = img.getAttribute("alt");

            loadData('GET', imgURL, imgID, imgClass, imgALT, img)
                .then(function (res) {
                    insertData(res);
                }).catch(function (err) {
                    console.error('Coś poszło nie tak');
                })
        });

    }

}

function insertData(res) {
    console.log(res);
}

function loadData(method, url, imgID, imgClass, imgALT, img) {
    return ajax(method, url, imgID, imgClass, imgALT, img);
}

function ajax(method, url, imgID, imgClass, imgALT, img) {
    
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.addEventListener('load', function () {
            if (this.status === 200) {

                //resolve(xhr.response);
                if (xhr.responseXML) {
                    let svg = xhr.responseXML.querySelector('svg');

                    if (typeof imgID !== 'undefined' && imgID) {
                        svg.id = imgID;
                    }
                    if (typeof imgClass !== 'undefined') {
                        svg.classList.add('replaced-svg');
                        svg.classList.add(imgClass);
                    }
                    if (typeof imgALT !== 'undefined' && imgALT) {
                        const title = document.createElement("title");
                        title.innerText = imgALT;
                        svg.prepend(title);
                    }

                    svg.removeAttribute('xmlns:a');

                    if (!svg.hasAttribute('viewBox') && svg.hasAttribute('height') && svg.hasAttribute('width')) {
                        svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
                    }

                    img.replaceWith(svg);

                    img.style.opacity = 1;
                }
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        });
        xhr.addEventListener('error', function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        });

        xhr.send();
    });
}

window.svg = new convertSVG();
window.svg.convertSVG();


