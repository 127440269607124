// Get all elements that needs type of device that there is
const addDeviceType = document.querySelectorAll(`[data-device="handle"]`);
if (addDeviceType.length > 0) {
    // Get device type from meta tag
    const deviceType = document.querySelector(`[name="bw:device"]`).getAttribute("content");
    const className = "device--" + deviceType;

    addDeviceType.forEach(element => {
        element.classList.add(className);
    })
}